import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

/**
 * Contact Component
 */
export class ContactComponent implements OnInit {

  public contactForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,3}')]),
    phone: new UntypedFormControl(null),
    text: new UntypedFormControl(null, Validators.required)
  });

  public isSending = false;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private contactService: ContactService
  ) { }

  ngOnInit(): void {
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  sendEmail(): void {
    if(this.contactForm.valid) {
      this.isSending = true;
      const name = this.contactForm.get('name').value;
      const email = this.contactForm.get('email').value;
      const phone = this.contactForm.get('phone').value;
      const text = this.contactForm.get('text').value;
      this.contactService.sendEmail(name, email, phone, text).subscribe(
        () => {
          this.isSending = false;
          this.contactForm.reset();
          this.toastr.success('Λάβαμε το μήνυμα και κάποιος εκπρόσωπος μας θα επικοινωνήσει σύντομα μαζί σας.', 'Επιτυχής αποστολή!');
        },
        () => {
          this.isSending = false;
          this.toastr.error('Κάποιο σφάλμα προέκυψε κατά την αποστολή του μηνύματος σας.', 'Σφάλμα!');
        }
      );
    } else {
      this.contactForm.markAllAsTouched();
      this.toastr.warning('Παρακαλούμε συμπληρώστε ορθά όλα τα υποχρεωτικά πεδία στην φόρμα επικοινωνίας.', 'Προσοχή!');
    }
  }
}
