import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})

/**
 * About Us Component
 */
export class AboutUsComponent implements OnInit {
  yearsCount: number;
  initYear = 2016;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.yearsCount = new Date().getUTCFullYear() - this.initYear;
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

}
