<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/aboutus.jpg');">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level title-heading">
          <h1 class="text-white title-dark"> Σχετικά με εμάς </h1>
          <p class="text-white-50 para-desc mb-0 mx-auto">Γνωρίστε την ομάδα του Orderify</p>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Orderify</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Company</a></li>
                <li class="breadcrumb-item active" aria-current="page">Aboutus</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/company/about2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="yearsCount" [from]="0"
                [duration]="4"></span>+</span>
            <span class="h6 align-self-end ms-2">Χρόνια <br> Εμπειρίας</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Ποιοί είμαστε ?</h4>
            <p class="text-muted">Η <span class="text-primary fw-bold">Orderify</span> ιδρύθηκε το {{ initYear }}, 
              ειδικεύεται στα συστήματα παραγγελιοληψιών και το ανθρωπινό δυναμικό της αποτελείται από 
              πτυχιούχους Μηχανικών Πληροφοριακών και Επικοινωνιακών Συστημάτων, οι οποίοι γνωρίζουν τις 
              σημερινές ανάγκες των επιχειρήσεων εστίασης και διασκέδασης για καλύτερη διαχειρίση, 
              εξυπηρέτηση και αποτελεσματικότητα των πελατών τους.</p>
            <a routerLink="/contact" class="btn btn-primary mt-3">Επικοινωνήστε μαζί μας</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- <div class="container mt-5">
    <app-clients-logo></app-clients-logo>
  </div> -->
  <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Orderify</h6>
          <h4 class="title mb-4">Πως λειτουργούμε</h4>
          <p class="text-muted para-desc mx-auto mb-0">Μάθετε πως λειτουργεί η ομάδα του <span
              class="text-primary fw-bold">Orderify</span> προκειμένου να σας προσφέρει τις καλύτερα δυνατές λύσεις.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Σχεδιασμός</h5>
            <p class="text-muted mb-0">Σχεδιάζουμε την λύση που θα σας προσφέρει τα μέγιστα αποτελέσματα, αρκετές φορές μέσα από 
              το δικό σας feedback.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Υλοποίηση & Δοκιμές</h5>
            <p class="text-muted mb-0">Υλοποιούμε την λύση που επιθύμουμε και την δοκιμάζουμε εξονυχιστικά.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Κυκλοφορία</h5>
            <p class="text-muted mb-0">Αφού κυκλοφορήσουν οι νέες εκδόσεις μας, περιμένουμε την δικιά σας γνώμη και προτάσεις για 
              μελλοντικές αναβαθμίσεις.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->