import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})

/***
 * Applications Component
 */
export class ApplicationsComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  ngOnInit(): void {
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
   openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openTrialModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * Services Data
   */
   servicesData = [
    {
      icon: "mdi mdi-cellphone-wireless h1 text-primary",
      title: "Παραγγελιοληψία",
      description: "χωρίς λάθη, με άμεση ενημέρωση για αύξηση της αποδοτικότητας."
    },
    {
      icon: "mdi mdi-account-group h1 text-primary",
      title: "Απεριόριστοι",
      description: "χρήστες και ρόλοι του καταστήματος (μπάρ, σερβιτόροι, κουζίνα)."
    },
    {
      icon: "mdi mdi-currency-eur-off h1 text-primary",
      title: "Οικονομικό",
      description: "χωρίς κρυφά κόστη, με φθηνό εξοπλισμό και σταθερή συνδρομή."
    },
    {
      icon: "mdi mdi-cellphone-text h1 text-primary",
      title: "Διαχείριση",
      description: "απομακρυσμένα και εύκολα ακόμη και από το κινητό σας."
    },
    {
      icon: "mdi mdi-chart-bar h1 text-primary",
      title: "Στατιστικά",
      description: "πλήρως παραμετροποιήσιμα για πλήρη εικόνα και έλεγχο της επιχείρησης σας."
    },
    {
      icon: "mdi mdi-face-agent h1 text-primary",
      title: "Υποστήριξη",
      description: "διαρκής και άμεση με δυνατότητα 365 ημερών για να είστε πάντα ήσυχοι."
    }
  ];
}
