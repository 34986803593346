<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100"
         id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Συχνές Ερωτήσεις </h4>
          <p class="mt-2 text-muted">και οι απαντήσεις τους...</p>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Orderify</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                <li class="breadcrumb-item active" aria-current="page">FAQs</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
        <div class="rounded shadow p-4 sticky-bar">
          <ul class="list-unstyled mb-0">
            <li><a href="javascript: void(0);"
                 [ngxScrollTo]="'#general'"
                 class="mouse-down h6 text-dark">Γενικές
                Ερωτήσεις</a></li>
            <li class="mt-4"><a href="javascript: void(0);"
                 [ngxScrollTo]="'#tech'"
                 class="mouse-down h6 text-dark">Ερωτήσεις
                Αγοράς</a></li>
            <li class="mt-4"><a href="javascript: void(0);"
                 [ngxScrollTo]="'#payment'"
                 class="mouse-down h6 text-dark">Ερωτήσεις
                Πληρωμών</a></li>
            <li class="mt-4"><a href="javascript: void(0);"
                 [ngxScrollTo]="'#support'"
                 class="mouse-down h6 text-dark">Ερωτήσεις
                Υποστήριξης</a></li>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title"
             id="general">
          <h4>Γενικές Ερωτήσεις</h4>
        </div>
        <div class="faq-content mt-4 pt-2">
          <ngb-accordion #acc="ngbAccordion"
                         activeIds="ngb-panel-0"
                         closeOthers="true">
            <ngb-panel id="ngb-panel-0"
                       cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0"
                     id="headingone">
                  <h6 class="title mb-0 text-dark"> Πόσο εύκολη είναι η εγκατάσταση του <span
                          class="text-primary bold">Orderify</span>;</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Η εγκατάσταση του Orderify είναι πραγματικά πολύ εύκολη. Μπορείτε να
                  ακολουθήσετε τον
                  πλήρη οδηγό που σας παρέχουμε με την αγορά σας και φυσικά κάποιος εκπρόσωπος μας θα βρίσκεται πάντα
                  κοντά σας για οτιδήποτε χρειαστείτε.
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Τι εξοπλισμό θα χρειαστεί να αγοράσω; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Το Orderify σας δίνει την ελευθερία να επιλέξετε πόσες και τι
                  συσκευές θα χρησιμοποιήσετε
                  στο κατάστημα σας. Πέρα από κάποιον υπολογιστή ή tablet το οποίο πρέπει να λειτουργεί συνέχεια
                  προκειμένου να λαμβάνονται οι παραγγελίες, μπορείτε
                  να τοποθετήσετε όσους θερμικούς εκτυπωτές χρειάζεστε στα διάφορα πόστα. Οι σερβιτόροι μπορούν να
                  χρησιμοποιούν ακόμη και τις ίδιες τις συσκευές τους.
                  Η αρχική εγκατάσταση και ρύθμιση μπορεί να γίνει από οποιοδήποτε υπολογιστή ο οποίος στην συνέχεια δεν
                  είναι απαραίτητο να λειτουργεί διαρκώς.
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingthree">
                  <h6 class="title mb-0 text-dark"
                      style="text-align: left;"> Ποιες οι διαφορές μιας <span class="bold">Cloud</span> υπηρεσίας από
                    την αγορά και την εγκατάσταση ενός τοπικού συστήματος; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Η cloud αρχιτεκτονική του Orderify που προσφέρουμε είναι μία λύση που
                  "τη ρυθμίζετε μία φορά και μετά την ξεχνάτε".
                  Η cloud αρχιτεκτονική της εξασφαλίζει ότι έχετε πρόσβαση στα δεδομένα σας από παντού,
                  αναβαθμίζεται τακτικά χωρίς τη δική σας παρέμβαση και παρέχει στο κατάστημα σας την απαιτούμενη νομική
                  και κανονιστική συμμόρφωση
                  όσον αφορά τα θέματα της διαθεσιμότητας, της ασφάλειας και της προστασίας των δεδομένων από αλλοίωση.
                  Από την άλλη πλευρά, η αγορά και εγκατάσταση ενός τοπικού συστήματος προσφέρει περιορισμένες
                  δυνατότητες πρόβασης στα δεδομένα σας,
                  ενημερώνεται πιο σπάνια και περιπλέκει τα πράγματα στα ζητήματα της συμμόρφωσης του καταστήματος
                  σας με τη νομοθεσία των προσωπικών δεδομένων. Πολύ πιθανόν επίσης να απαιτηθεί η αγορά νέας έκδοσης
                  της εφαρμογής σε 1-2 χρόνια,
                  με επιπρόσθετο κόστος πέραν του ποσού που καταβάλετε για τη διατήρηση του συμβολαίου υποστήριξης.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingfour">
                  <h6 class="title mb-0 text-dark"> Πως λειτουργεί ο <span class="text-primary bold">Orderify</span>
                    <span class="bold"> Customer</span>;
                  </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Προκειμένου να χρησιμοποιήσετε τον Orderify Customer στο κατάστημα
                  σας, το μόνο που χρειάζετε είναι να εκτυπώσετε τα κατάλληλα
                  QR Banners μέσα από τον Orderify Admin (σε ότι μέγεθος και υλικό επιθυμείτε) και να τα τοποθετήσετε
                  στα κατάλληλα τραπέζια. Οι πελάτες σας σκανάροντας το QR Code
                  του τραπεζιού τους, συνδέονται αυτόματα στο τραπέζι τους από όπου μπορούν να δούν έναν δυναμικό
                  κατάλογο (πλήρως παραμετροποιήσιμο μενού για τους πελάτες μέσα από τον Admin)
                  να παραγγείλουν αν το επιθυμούν και να καλέσουν τον σερβιτόρο τους εύκολα με το πάτημα ενός κουμπιού
                  (η δυνατότητα αυτή είναι διαθέσιμη κάθε 5 λεπτά).
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="section-title mt-5"
             id="general">
          <h4>Ερωτήσεις Αγοράς</h4>
        </div>

        <div class="faq-content mt-4 pt-3">

          <ngb-accordion #acc="ngbAccordion"
                         activeIds="ngb-panelfaq"
                         closeOthers="true">
            <ngb-panel id="ngb-panelfaq"
                       cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingone">
                  <h6 class="title mb-0 text-dark"> Πως μπορώ να αγοράσω την υπηρεσία σας; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Κάντε την <a href="https://admin.orderify.gr/register"
                     target="blank"
                     class="text-primary">εγγραφή</a> σας στο σύστημα μας, και ξεκινήστε
                  να χρησιμοποιήσετε την εφαρμογή μας άμεσα και με δυνατότητα για πλήρη επιστροφή των χρημάτων σας μέσα
                  στον πρώτο μήνα.
                  Μπορείτε να εξοφλείτε τη συνδρομή σας, μέσω της χρεωστικής ή πιστωτικής κάρτας σας VISA, MasterCard,
                  Maestro, Diners, American Express
                  ή με μεταφορά του ποσού σε τραπεζικό λογαριασμό μας.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Τι περιλαμβάνει το κόστος συνδρομής; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Η συνδρομή στην υπηρεσία μας περιλαμβάνει την πρόσβαση στις εφαρμογές μας από όλες τις συσκευές σας
                  μέσω του cloud,
                  τη φιλοξενία των δεδομένων σας, τη νομική συμμόρφωση της εφαρμογής, συμβουλευτικές και εκπαιδευτικές
                  υπηρεσίες,
                  την τεχνική υποστήριξη για θέματα της εφαρμογής και φυσικά όλες τις αναβαθμίσεις των εφαρμογών.
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingthree">
                  <h6 class="title mb-0 text-dark"> Με ποιά κριτήρια έχει οριστεί το αντίτιμο της υπηρεσίας; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Έχουμε εκπονήσει μελέτη Εκτίμησης Αντικτύπου Προσωπικών Δεδομένων και έχουμε υπολογίσει προσεκτικά το
                  κόστος εργασίας
                  μας έτσι ώστε να σας παρέχουμε μία ασφαλή εφαρμογή και μία αξιόπιστη και μακρόπνοη συνεργασία. Στο
                  κόστος μας έχουμε συμπεριλάβει τη συμμόρφωση με το
                  ισχύον νομικό και κανονιστικό πλαίσιο, τη διαφάνεια και τον ποιοτικό έλεγχο των συστημάτων μας.
                </p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingfour">
                  <h6 class="title mb-0 text-dark"> Πως λειτουργεί η δωρέαν περίοδος των 7 ημερών και η δοκιμαστική
                    περίοδος του ενός μηνός;</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Με την εγγραφή σας στο Orderify και την δημιουργία του καταστήματος
                  σας έχετε άμεση πρόσβαση στην πλήρη έκδοση της εφαρμογής μας για 7 ημέρες. Στην συνέχεια, αν αγοράσετε
                  κάποιο πακέτο αλλά για κάποιο λόγο αποφασίσετε να μην συνεχίσετε
                  να χρησιμοποιείτε την υπηρεσία μας, σας δίνουμε την δυνατότητα ακύρωσης της συνδρομής και πλήρη
                  επιστροφή των χρημάτων σας μέσα στο διάστημα του πρώτου μήνα. Δεν κάνουμε επιπλέον ερωτήσεις.
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div class="section-title mt-5"
             id="payment">
          <h4>Ερωτήσεις Πληρωμών</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion"
                         activeIds="ngb-panelpayment"
                         closeOthers="true">
            <ngb-panel id="ngb-panelpayment"
                       cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingone">
                  <h6 class="title mb-0 text-dark"> Υπάρχουν κρυφές χρεώσεις στην υπηρεσία σας; </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Δεν υπάρχει κανένα απολύτως κρυφό κόστος και χρεώσεις στην υπηρεσία
                  μας.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingtwo">
                  <h6 class="title mb-0 text-dark"> Οι πελάτες του καταστήματος χρεώνονται κάτι για την χρήση του <span
                          class="text-primary bold">Orderify</span> <span class="bold"> Customer</span>;</h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Η χρήση του Orderify Customer είναι εντελώς δωρεάν για τους πελάτες
                  καθώς την πληρώνετε εσείς μέσα
                  από την συνδρομή σας. Δεν υπάρχουν ούτε διαφημίσεις, ούτε κρυφές χρεώσεις μέσα στην πλατφόρμα.
                </p>
              </ng-template>
            </ngb-panel>
            <!-- <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel> -->
          </ngb-accordion>
        </div>

        <div class="section-title mt-5"
             id="support">
          <h4>Ερωτήσεις Υποστήριξης</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion"
                         activeIds="ngb-panelsupport"
                         closeOthers="true">
            <ngb-panel id="ngb-panelsupport"
                       cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 "
                     id="headingone">
                  <h6 class="title mb-0 text-dark">Τι υποστήριξη παρέχει το <span class="text-primary">Orderify</span>;
                  </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">Η επιτυχία των πελατών μας είναι πάντα η προτεραιότητα για όλους εμάς
                  στο <span class="text-primary">Orderify</span>.
                  Για το λόγο αυτό παρέχουμε τηλεφωνική υποστήριξη 24/7 σε εσάς για ότι πρόβλημα ή ερώτηση προκύψει.
                </p>
              </ng-template>
            </ngb-panel>
            <!-- <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> What is the main process open account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> Is <span class="text-primary">Landrick</span> safer to use with my
                    account ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>
            <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingfour">
                  <h6 class="title mb-0 text-dark"> How can i contact your technical team ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel> -->
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row"
         style="justify-content:center">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)"
                 class="text-dark">Έχετε άλλες απορίες;</a></h5>
            <p class="text-muted mb-0">Αν δεν σας κάλυψαν οι παραπάνω απαντήσεις, επικοινωνήστε απευθείας μαζί μας.</p>
            <div class="mt-2">
              <a routerLink="/contact"
                 class="btn btn-sm btn-soft-primary">Επικοινωνία</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);"
   class="btn btn-icon btn-primary back-to-top"
   id="back-to-top"
   [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up"
             class="icons"></i-feather>
</a>
<!-- Back to top -->