import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

const uri = environment.urls.contact;
@Injectable({ providedIn: 'root' })
export class ContactService {
    constructor(private httpClient: HttpClient) {}

    public sendEmail(name: string, email: string, phone: string, text: string): Observable<boolean> {
        const body = new HttpParams()
            .set('name', name)
            .set('email', email)
            .set('phone', phone)
            .set('text', text);
        return this.httpClient.post(`${uri}/email`,
            body
        ).pipe(
            map(result => true),
            catchError(error => {
                console.error(error);
                return of(false);
            })
        );
    }
}