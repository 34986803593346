import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './core/components/components/components.component';
import { HelpcenterFaqsComponent } from './core/components/helpcenter-faqs/helpcenter-faqs.component';
import { ApplicationsComponent } from './core/components/applications/applications.component';
import { IndexComponent } from './core/components/index/index.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { AboutUsComponent } from './core/components/about-us/about-us.component';
import { ContactComponent } from './core/components/contact/contact.component';
import { ErrorComponent } from './core/components/error/error.component';
import { PageThankyouComponent } from './core/components/page-thankyou/page-thankyou.component';
import { PricingComponent } from './core/components/pricing/pricing.component';
import { PrivacyComponent } from './core/components/privacy/privacy.component';
import { TermsComponent } from './core/components/terms/terms.component';
import { EmailAlertComponent } from './email/email-alert/email-alert.component';
import { EmailConfirmationComponent } from './email/email-confirmation/email-confirmation.component';
import { EmailInvoiceComponent } from './email/email-invoice/email-invoice.component';
import { EmailPasswordResetComponent } from './email/email-password-reset/email-password-reset.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      // { path: 'components', component: ComponentsComponent },
      { path: 'helpcenter-faqs', component: HelpcenterFaqsComponent },
      { path: 'applications', component: ApplicationsComponent },

      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'terms', component: TermsComponent },
      { path: '#', component: SwitcherComponent }
    ]
  },

  { path: 'error', component: ErrorComponent },
  { path: 'page-thankyou', component: PageThankyouComponent },

  // { path: 'email-alert', component: EmailAlertComponent },
  // { path: 'email-confirmation', component: EmailConfirmationComponent },
  // { path: 'email-invoice', component: EmailInvoiceComponent },
  // { path: 'email-password-reset', component: EmailPasswordResetComponent },
  {
    path: '**',
    redirectTo: 'error',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
