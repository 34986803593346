import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ScrollspyDirective } from './scrollspy.directive';
import { FeatherModule } from 'angular-feather';

import { ClientsLogoComponent } from './clients-logo/clients-logo.component';
import { ServicesComponent } from './services/services.component';
import { SimplePricingComponent } from './simple-pricing/simple-pricing.component';
import { SharedPricingComponent } from './shared-pricing/shared-pricing.component';

@NgModule({
  declarations: [
    ScrollspyDirective,
    ClientsLogoComponent,
    ServicesComponent,
    SharedPricingComponent,
    SimplePricingComponent
  ],
  imports: [
    CommonModule,
    FeatherModule,
    RouterModule
  ],
  exports: [ 
    ScrollspyDirective,
    ClientsLogoComponent, 
    ServicesComponent, 
    SharedPricingComponent,
    SimplePricingComponent
  ]
})

export class SharedModule { }
