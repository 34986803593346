<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Όροι Χρήσης </h4>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Orderify</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Terms</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow border-0 rounded">
          <div class="card-body">
            <h5 class="card-title">Εισαγωγή:</h5>
            <p class="text-muted">Όλες οι υπηρεσίες που παρέχονται από την Orderify προς κάθε είδους 
              επισκέπτη, συνδρομητή ή εγγεγραμμένου μέλους σε κάποια από τις εφαρμογές της 
              γίνονται υπό τους ακόλουθους όρους. Η χρήση των υπηρεσιών συνεπάγεται αυτόματη 
              αποδοχή των παρακάτω όρων οι οποίοι καθορίζουν τους όρους που διέπουν την σχέση μεταξύ 
              της Orderify και χρήστη.</p>

            <h5 class="card-title">Αλλαγή και τροποποίηση όρων ή προνοιών ή προϋποθέσεων:</h5>
            <p class="text-muted">Η Orderify διατηρεί το δικαίωμα να αλλάξει και/ή τροποποιήσει 
              και/ή μεταβάλει και/ή αναθεωρήσει οποτεδήποτε και χωρίς προειδοποίηση τους παρόντες 
              όρους και προϋποθέσεις, οφείλει όμως να ενημερώνει το παρόν κείμενο στην ιστοσελίδα 
              της για κάθε τροποποίηση ή προσθήκη. Αν οι χρήστες συνεχίσουν τη χρήση των ιστοσελίδων
              και υπηρεσιών θα θεωρηθεί ότι έχουν αποδεχθεί τις μεταβολές των όρων και των 
              προϋποθέσεων.</p>

            <h5 class="card-title">Εγγραφή χρηστών:</h5>
            <p class="text-muted">Οι ανήλικοι απαγορεύεται να κάνουν χρήση των υπηρεσιών που σύμφωνα 
              με το νόμο απευθύνονται αποκλειστικά σε ενήλικες. Αν παρά ταύτα ανήλικοι αυτοβούλως 
              ιστοσελίδες ή κάνουν χρήση υπηρεσιών που είναι δυνατόν να θεωρηθούν ακατάλληλες για αυτούς, 
              η Orderify ουδεμία ευθύνη φέρει. Οι χρήστες θα πρέπει να εγγραφούν για τη χρήση υπηρεσιών και 
              να αποκτήσουν λογαριασμό χρήστη με επιλογή ονόματος χρήστη (user name) και κωδικού χρήστη 
              (user password) συμπληρώνοντας την σχετική φόρμα στις εφαρμογές της Orderify. 
              Σε αυτή την περίπτωση θα είναι υποχρεωμένοι να δώσουν ορισμένες προσωπικές πληροφορίες 
              (όπως όνομα, επίθετο, διεύθυνση, κλπ). Με την εγγραφή τους στις υπηρεσίες της Orderify 
              και την απόκτηση λογαριασμού χρήστη, οι χρήστες δίνουν την συγκατάθεση τους στην Orderify 
              να διατηρεί τα προσωπικά τους δεδομένα. Ταυτόχρονα, οι χρήστες έχουν το δικαίωμα όποτε το 
              ζητήσουν να ενημερώνονται για τα προσωπικά τους δεδομένα που διατηρεί η Orderify. 
              Οι χρήστες είναι υποχρεωμένοι να παρέχουν σωστές και ακριβείς πληροφορίες και είναι 
              υποχρεωμένοι να τηρούν πάντοτε ακριβή, αληθή, τρέχοντα, έγκυρα και πλήρη στοιχεία. 
              Εάν η Orderify έχει εύλογο λόγο να υποψιαστεί ότι τα παρεχόμενα στοιχεία είναι ανακριβή, 
              ψευδή ή μη ολοκληρωμένα, θα έχει το δικαίωμα να μην δημιουργήσει ή να τερματίσει το 
              λογαριασμό χρήστη ή να αρνηθεί τη χρήση ή μελλοντική χρήση των υπηρεσιών από το χρήστη 
              χωρίς καμία ειδοποίηση. Οι χρήστες είναι υπεύθυνοι για τη διατήρηση της εμπιστευτικότητας 
              και ασφαλής φύλαξης του κωδικού πρόσβασης και/ή ονόματος τους. Οι χρήστες θα είναι 
              υπεύθυνοι για όλες τις εργασίες, πράξεις ή άλλες δραστηριότητες που διενεργούνται με τον 
              κωδικό πρόσβασης και/ή όνομα χρήστη. Οι κωδικοί δεν μεταφέρονται και δεν εμπορεύονται. 
              Η Orderify δεν είναι υπεύθυνη και ούτε αναλαμβάνει ευθύνη για μη εξουσιοδοτημένη πρόσβαση 
              στις υπηρεσίες ή μη εξουσιοδοτημένη χρήση των υπηρεσιών ή μη εξουσιοδοτημένη χρήση του 
              κωδικού πρόσβασης και/ή του ονόματος χρήστη. Οι χρήστες είναι υπεύθυνοι για όλες τις 
              πράξεις που διενεργούνται με τον κωδικό πρόσβασης και το όνομα χρήστη και υποχρεούνται να 
              ειδοποιούν άμεσα την Orderify για οποιαδήποτε μη εξουσιοδοτημένη χρήση αυτών και για 
              οποιαδήποτε (ακόμα και για ενδεχόμενη) παραβίαση ασφαλείας. Η Orderify δεν ευθύνεται για 
              οποιαδήποτε βλάβη ή ζημία τυχόν προκύψει από την αυθαίρετη ή παράνομη χρήση του κωδικού 
              πρόσβασης και/ή ονόματος χρήστη από τρίτους, λόγω διαρροής του ή για οποιονδήποτε άλλο λόγο 
              και διατηρεί το δικαίωμα να ζητήσει την καταβολή αποζημίωσης από τον χρήστη, σε περίπτωση 
              που υποστεί οποιουδήποτε είδους ζημία από την αυθαίρετη ή παράνομη χρήση του κωδικού πρόσβασης. 
              Η Orderify δικαιούται οποτεδήποτε κατά την κρίση της να αρνηθεί την απονομή κωδικού πρόσβασης 
              ή να ακυρώσει απονεμηθέντα κωδικό ή να τερματίσει την παροχή των υπηρεσιών αυτών στο χρήστη 
              και να αρνηθεί οποιαδήποτε τρέχουσα ή μέλλουσα χρήση των υπηρεσιών, σε περίπτωση παραβίασης των 
              όρων χρήσης.</p>
            
            <h5 class="card-title">Συμπεριφορά χρηστών και χρήση των υπηρεσιών:</h5>
            <p class="text-muted">Δεν επιτρέπεται η αναπαραγωγή, διασκευή, αντιγραφή, πώληση, μεταπώληση, 
              ή με οποιονδήποτε τρόπο εκμετάλλευση του περιεχομένου των ιστοσελίδων της Orderify. 
              Η Orderify διατηρεί το δικαίωμα να αρνηθεί την παροχή υπηρεσιών συμπεριλαμβανομένων περιπτώσεων 
              όπου κρίνει ότι η συμπεριφορά του χρήστη έρχεται σε αντίθεση με οποιονδήποτε νόμο ή κανονισμό ή 
              και είναι ζημιογόνα των συμφερόντων της Orderify και των συνεργατών της. Οι χρήστες ρητά δηλώνουν 
              και αποδέχονται ότι είναι αποκλειστικά υπεύθυνοι για όλες τις πληροφορίες, κείμενα, μηνύματα 
              ή οποιοδήποτε άλλο περιεχόμενο μεταδίδεται μέσω των υπηρεσιών της Orderify. Η Orderify δεν είναι 
              υπεύθυνη να παρακολουθεί ή να ελέγχει το περιεχόμενο που αποθηκεύεται ή μεταδίδεται μέσω των 
              υπηρεσιών της και δεν μπορεί να εγγυηθεί την ορθότητα, εγκυρότητα ή ποιότητα του περιεχομένου 
              αυτού. Η Orderify δεν φέρει οποιαδήποτε ευθύνη για απώλειες ή ζημιές οποιασδήποτε φύσεως που θα 
              προκληθούν ως αποτέλεσμα του περιεχομένου που μεταδίδεται από τις υπηρεσίες.</p>

              
            <h5 class="card-title">Εμπορικά σήματα και εμπορικές επωνυμίες:</h5>
            <p class="text-muted">H Orderify καθώς και τα σήματα της αποτελούν εμπορικές επωνυμίες ή και 
              εμπορικά σήματα της. Απαγορεύεται η αντιγραφή, διασκευή, αναπαραγωγή, μετάδοση, κυκλοφορία, 
              επανέκδοση, εκτύπωση, χρήση και μετάφρασή τους από οποιονδήποτε για οποιονδήποτε σκοπό. Όλα τα 
              εμπορικά σήματα που χρησιμοποιούνται στις ιστοσελίδες ή/και τις υπηρεσίες, συμπεριλαμβανομένων 
              των εμπορικών σημάτων της Orderify, είναι σήματα κατοχυρωμένα και προστατεύονται με δικαιώματα 
              πνευματικής ιδιοκτησίας, εμπορικά σήματα, σήματα υπηρεσιών, διπλώματα ευρεσιτεχνίας ή άλλα 
              δικαιώματα ιδιοκτησίας και νόμους. Οι χρήστες ρητά συμφωνούν και αποδέχονται ότι θα κάνουν μόνο 
              δίκαιη χρήση των εμπορικών σημάτων και δεν θα χρησιμοποιούν τα εμπορικά σήματα, είτε είναι σχέδια, 
              είτε λέξεις: ως δικά τους εμπορικά σήματα ή ως τμήμα τους, Κκτά τρόπο που μπορεί να προκαλέσει 
              σύγχυση, για την αναγνώριση προϊόντων με τα οποία δεν έχουν σχέση, για να υπονοηθεί η έγκριση ή 
              κάτι παρόμοιο προϊόντων ή υπηρεσιών με τα οποία δεν έχουν σχέση με οποιονδήποτε τρόπο με τον 
              οποίο μπορεί να ζημιωθεί η φήμη της Orderify ή των εμπορικών της σημάτων.</p>
            

            <h5 class="card-title">Ευθύνες της <span class="bold-orderify">Orderify</span>:</h5>
            <p class="text-muted">Οι χρήστες της Orderify και των υπηρεσιών της αποδέχονται ότι η Orderify 
              δεν ευθύνεται για οποιαδήποτε απώλεια και/ή ζημιά άμεση και/ή έμμεση αναφορικά με οτιδήποτε 
              δημοσιεύεται στις σελίδες της Orderify και/ή αναφορικά με οποιαδήποτε υπηρεσία ή/και εφαρμογή 
              της Orderify. Οι χρήστες της Orderify δηλώνουν ότι αποποιούνται οποιουδήποτε δικαιώματος 
              αναφορικά με οποιαδήποτε υπηρεσία της Orderify που πηγάζει από οποιονδήποτε υπάρχοντα ή 
              μελλοντικό νόμο οπουδήποτε στον κόσμο. Η Orderify με το παρόν αποκλείει όλες τις ευθύνες 
              οποιουδήποτε είδους για τη μετάδοση ή τη λήψη ακατάλληλου περιεχομένου οποιασδήποτε φύσης. Με το 
              παρόν οι χρήστες συμφωνούν να αποζημιώνουν πλήρως την Orderify και να μην εγείρουν εναντίον της 
              απαιτήσεις έναντι οποιασδήποτε αξίωσης έχουν εγείρει τρίτοι ως αποτέλεσμα της χρήσης των 
              ιστοσελίδων και των υπηρεσιών/εφαρμογών της Orderify από αυτούς και σε σχέση με όλων των ειδών 
              απώλειες, δαπάνες, ενέργειες, διαδικασίες, αξιώσεις, ζημίες, έξοδα (συμπεριλαμβανομένων των 
              εύλογων νομικών εξόδων και δαπανών) ή ευθύνες που προέκυψαν ή επήλθαν, άμεσα ή έμμεσα, από την 
              Orderify ως αποτέλεσμα τέτοιου είδους χρήσης των ιστοσελίδων και των υπηρεσιών ή της παραβίασης 
              εκ μέρους των χρηστών ή της μη τήρησης αυτών των όρων και των προϋποθέσεων. Οι χρήστες θα 
              υπερασπίζονται και θα καταβάλλουν κάθε δαπάνη, ζημία, επιδικασμένο ποσό, αμοιβή (περιλαμβανομένων 
              εύλογων νομικών αμοιβών) και αποζημιώσεις που θα επιβληθούν στην Orderify ως απόρροια των 
              παραπάνω αξιώσεων και θα παρέχουν στην Orderify ενημέρωση για αυτές τις αξιώσεις, πλήρη 
              εξουσιοδότηση να υπερασπιστεί, να συμβιβαστεί ή να διακανονίσει αυτές τις αξιώσεις και την εύλογη 
              απαραίτητη υποστήριξη για να υπερασπιστεί αυτές τις αξιώσεις, με αποκλειστικά δικές τους δαπάνες.</p>
            
            <p class="text-muted">
              <b class="text-danger">Ειδικότερα ο κάθε χρήστης θα πρέπει να τηρεί το νόμο Ν 3471/2006: Προστασία προσωπικών δεδομένων & ιδιωτικής ζωής στις ηλεκτρονικές επικοινωνίες:</b>
            </p>

            <h5 class="card-title">Άρθρο 11 - Μη ζητηθείσα επικοινωνία:</h5>
            <p class="text-muted">1. Η χρησιμοποίηση αυτόματων συστημάτων κλήσης, ιδίως με χρήση συσκευών τηλεομοιοτυπίας 
              (φαξ) ή ηλεκτρονικού ταχυδρομείου, και γενικότερα η πραγματοποίηση μη ζητηθεισών επικοινωνιών με 
              οποιοδήποτε μέσο ηλεκτρονικής επικοινωνίας, με ή χωρίς ανθρώπινη παρέμβαση, για σκοπούς απευθείας 
              εμπορικής προώθησης προϊόντων ή υπηρεσιών και για κάθε είδους διαφημιστικούς σκοπούς, επιτρέπεται 
              μόνο αν ο συνδρομητής συγκατατεθεί εκ των προτέρων ρητώς.</p>
            
            <p class="text-muted">2. Δεν επιτρέπεται η πραγματοποίηση μη ζητηθεισών επικοινωνιών για τους ανωτέρω 
              σκοπούς, εφόσον ο συνδρομητής έχει δηλώσει προς τον φορέα παροχής διαθεσίμων στο κοινό υπηρεσιών 
              ηλεκτρονικών επικοινωνιών ότι δεν επιθυμεί γενικώς να δέχεται τέτοιες επικοινωνίες. Ο φορέας 
              υποχρεούται να καταχωρίζει δωρεάν τις δηλώσεις αυτές σε ειδικό κατάλογο συνδρομητών, ο οποίος 
              είναι στη διάθεση κάθε ενδιαφερομένου.</p>

            <p class="text-muted">3. Τα στοιχεία επαφής ηλεκτρονικού ταχυδρομείου που αποκτήθηκαν νομίμως, στο πλαίσιο 
              της πώλησης προϊόντων ή υπηρεσιών ή άλλης συναλλαγής, μπορούν να χρησιμοποιούνται για την 
              απευθείας προώθηση παρόμοιων προϊόντων ή υπηρεσιών του προμηθευτή ή για την εξυπηρέτηση παρόμοιων 
              σκοπών, ακόμη και ότανο αποδέκτης του μηνύματος δεν έχει δώσει εκ των προτέρων τη συγκατάθεσή του, 
              υπό την προϋπόθεση ότι του παρέχεται κατά τρόπο σαφή και ευδιάκριτο η δυνατότητα να αντιτάσσεται, 
              με εύκολο τρόπο και δωρεάν, στη συλλογήκαι χρησιμοποίηση των ηλεκτρονικών του στοιχείων, και αυτό 
              σε κάθε μήνυμα σε περίπτωση που ο χρήστης αρχικά δεν είχε διαφωνήσει σε αυτή τη χρήση.</p>

            <p class="text-muted">4. Απαγορεύεται η αποστολή μηνυμάτων ηλεκτρονικού ταχυδρομείου, που έχουν σκοπό την 
              άμεση εμπορική προώθηση προϊόντων και υπηρεσιών, όταν δεν αναφέρεται ευδιάκριτα και σαφώς η 
              ταυτότητα του αποστολέα ή του προσώπου προς όφελος του οποίου αποστέλλεται το μήνυμα, καθώς 
              επίσης και η έγκυρη διεύθυνση στην οποία ο αποδέκτης του μηνύματος μπορεί να ζητεί τον τερματισμό 
              της επικοινωνίας.</p>
            
            <p class="text-muted">5. Οι ανωτέρω ρυθμίσεις ισχύουν και για τους συνδρομητές που είναι νομικά πρόσωπα. 
              Για περισσότερες πληροφορίες πιέστε 
              <a href="https://www.dpa.gr/el/enimerwtiko" target="blank" class="text-primary">εδώ</a>.</p>

            <p class="text-muted">Οι όροι χρήσης των υπηρεσιών της Orderify διέπονται από το ελληνικό δίκαιο, 
              το ευρωπαϊκό δίκαιο και τους νόμους του διεθνούς εμπορίου. Σε κάθε περίπτωση αντιδικίας, 
              αρμόδια είναι τα δικαστήρια της Καβάλας. Στην περίπτωση που κάποια από τις παρούσες διατάξεις 
              κριθεί ως μη εφαρμόσιμη ή καταχρηστική, οι υπόλοιποι όροι χρήσης της παρούσας σελίδας και των 
              όρων προστασίας απορρήτου παραμένουν σε πλήρη ισχύ.</p>

          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->