<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Πολιτκή Απορρήτου </h4>
          <ul class="list-unstyled mt-4">
            <li class="list-inline-item h6 date text-muted"> <span class="text-dark">Τελευταία αναθεώρηση :</span> 24 Ιαν,
              2022</li>
          </ul>
          <!-- <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Orderify</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Utility</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy</li>
              </ul>
            </nav>
          </div> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-9">
        <div class="card shadow rounded border-0">
          <div class="card-body">
            <h5 class="card-title">Δήλωση Προστασίας Δεδομένων :</h5>
            <p class="text-muted">Η ατομική επιχείρηση με την επωνυμία Μπόνης Αθανάσιος του Δημητρίου ως Υπεύθυνη Επεξεργασίας Δεδομένων Προσωπικού χαρακτήρα, 
              εφεξής καλούμενη η «Επιχείρηση», στο πλαίσιο του Γενικού Κανονισμού Προστασίας των Δεδομένων (ΕΕ) 2016/679 που τέθηκε σε 
              ισχύ από την 25/05/2018, στο εξής ο «ΓΚΠΔ», όπως εκάστοτε ισχύει, σας παρέχει με το παρόν την ακόλουθη ενημέρωση για την 
              επεξεργασία των δεδομένων σας προσωπικού χαρακτήρα και τα δικαιώματά σας ως υποκειμένου της επεξεργασίας.</p>
            <p class="text-muted">Ο νέος Κανονισμός αντικαθιστά το υφιστάμενο νομικό πλαίσιο για την προστασία του ατόμου από την επεξεργασία 
              προσωπικών δεδομένων. Από την παραπάνω ημερομηνία κάθε αναφορά στις διατάξεις του Ν. 2472/1997, νοείται ότι αναφέρεται στις διατάξεις 
              του νέου «ΓΚΠΔ». Η παρούσα ενημέρωση απευθύνεται σε φυσικά πρόσωπα που διενεργούν οποιαδήποτε συναλλαγή με την Επιχείρηση, όπως ενδεικτικά
              σε Πελάτες που διατηρούν μόνιμη σχέση συνεργασίας με την Επιχείρηση στους νόμιμους ανά περίπτωση εκπροσώπους αυτών καθώς και στους ειδικούς 
              ή καθολικούς τους διαδόχους, σε εκπροσώπους νομικών προσώπων και σε κάθε φυσικό πρόσωπο που υπό οποιαδήποτε ιδιότητα έχει συναλλακτικές 
              σχέσεις με την Επιχείρηση.</p>
            <p class="text-muted">Η επεξεργασία των δεδομένων προσωπικού χαρακτήρα συνίσταται στη συλλογή, καταχώριση, οργάνωση, διάρθρωση, αποθήκευση, μεταβολή, 
              ανάκτηση, αναζήτηση πληροφοριών, χρήση, διαβίβαση, περιορισμό ή διαγραφή των δεδομένων προσωπικού χαρακτήρα που έχουν περιέλθει ή θα περιέλθουν σε 
              γνώση της Επιχείρησης, είτε στο πλαίσιο των συναλλακτικών σας σχέσεων με αυτήν είτε στο πλαίσιο πληροφόρησης που λαμβάνει η Επιχείρηση από τρίτο 
              φυσικό ή νομικό πρόσωπο ή φορέα του δημοσίου τομέα κατά την άσκηση νομίμου δικαιώματός αυτού ή της ίδιας της Επιχείρησης.</p>
            <p class="text-muted">Η Επιχείρηση σε συμμόρφωση με το ισχύον νομοθετικό πλαίσιο έχει προβεί σε όλες τις ενέργειες που απαιτούνται, εφαρμόζοντας τα 
              κατάλληλα τεχνικά και οργανωτικά μέτρα για τη νόμιμη τήρηση, την επεξεργασία και την ασφαλή φύλαξη του αρχείου δεδομένων προσωπικού χαρακτήρα 
              δεσμευόμενη να διασφαλίζει και να προστατεύει με κάθε τρόπο την επεξεργασία των προσωπικών σας δεδομένων από απώλεια ή διαρροή, αλλοίωση, 
              διαβίβαση ή την με οποιονδήποτε άλλο τρόπο αθέμιτη επεξεργασία τους.</p>

            <h5 class="card-title">1. Ποια Δεδομένα Συλλέγονται</h5>
            <p class="text-muted">Η Επιχείρηση επεξεργάζεται δεδομένα προσωπικού χαρακτήρα τα οποία έχετε υποβάλει ή πρόκειται να υποβάλετε στην Επιχείρηση 
              εσείς ή οι νόμιμοι εκπρόσωποί σας και τα οποία είναι απαραίτητα για την έναρξη, τη διατήρηση και την εκτέλεση των συναλλακτικών σας σχέσεων με την 
              Επιχείρηση, υφιστάμενων ή μελλοντικών ανάλογα με την παρεχόμενη υπηρεσία. Τα δεδομένα προσωπικού χαρακτήρα που παρέχετε στην Επιχείρηση πρέπει να 
              είναι πλήρη και ακριβή και να επικαιροποιούνται με δική σας επιμέλεια άμεσα, σε κάθε περίπτωση μεταβολής τους ή όποτε άλλοτε κρίνεται απαραίτητο 
              από την Επιχείρηση για τη διατήρηση των συναλλακτικών σας σχέσεων ή σε εκπλήρωση υποχρέωσης της Επιχείρησης που απορρέει από το νόμο και τις εκάστοτε 
              ισχύουσες κανονιστικές διατάξεις.</p>
            <p class="text-muted">Η Επιχείρηση δύναται επίσης να επεξεργάζεται δεδομένα σας που συλλέγει από τρίτες δημόσια προσβάσιμες πηγές 
              (π.χ. εμπορικά μητρώα, διαδίκτυο) εφόσον τα δεδομένα αυτά είναι απαραίτητα για τους σκοπούς της επεξεργασίας.</p>
            <p class="text-muted">Η συλλογή και η επεξεργασία των ανωτέρω δεδομένων προσωπικού χαρακτήρα από την Επιχείρηση είναι απαραίτητη για την έναρξη, 
              την εκτέλεση και τη διατήρηση των μεταξύ μας συναλλακτικών σχέσεων. Τυχόν αντίρρησή σας ως προς την παροχή ή την επεξεργασία των προσωπικών σας 
              δεδομένων ενδέχεται να οδηγήσει σε αδυναμία έναρξης ή συνέχισης της συνεργασίας σας με την επιχείρηση.</p>
            
            <h5 class="card-title">2. Συλλογή Δεδομένων Ανηλίκων</h5>
            <p class="text-muted">Η Επιχείρηση δεν επιτρέπει την παροχή υπηρεσιών σε ανηλίκους, και ως εκ τούτου δεν τηρεί δεδομένα ανηλίκων προσώπων.</p>

            <h5 class="card-title">3. Νομιμότητα Επεξεργασίας</h5>
            <p class="text-muted">Η Επιχείρηση επεξεργάζεται νόμιμα δεδομένα προσωπικού χαρακτήρα εφόσον επεξεργασίας η επεξεργασία:</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Είναι απαραίτητη για την εξυπηρέτηση, υποστήριξη και παρακολούθηση των συναλλακτικών σας σχέσεων με την Επιχείρηση και την ορθή εκτέλεση των μεταξύ σας συμβάσεων.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Είναι απαραίτητη για τη συμμόρφωση της Επιχείρησης με έννομη υποχρέωσή της ή για την επιδίωξη των εννόμων συμφερόντων της που απορρέουν από τις συναλλακτικές 
                σας σχέσεις με την Επιχείρησης ή από Ενημέρωση για την επεξεργασία δεδομένων προσωπικού χαρακτήρα άλλα δικαιώματά της που απορρέουν από το νόμο.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Είναι απαραίτητη για την εκπλήρωση καθήκοντός της που εκτελείται προς το δημόσιο συμφέρον στο πλαίσιο του εκάστοτε ισχύοντος νομοθετικού και κανονιστικού πλαισίου.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Βασίζεται στην προηγούμενη ρητή συναίνεσή σας, εφόσον η επεξεργασία δεν θεμελιώνεται σε κάποια από τις ανωτέρω αναφερόμενες νόμιμες βάσεις επεξεργασίας.
              </li>
            </ul>

            <h5 class="card-title">4. Σκοποί της Επεξεργασίας</h5>
            <p class="text-muted">Η επεξεργασία των προσωπικών δεδομένων σας αφορά:</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την εξυπηρέτηση, υποστήριξη και παρακολούθηση των συναλλακτικών σας σχέσεων με την Επιχείρηση, την ορθή εκτέλεση των μεταξύ σας συμβάσεων, 
                την εκπλήρωση των υποχρεώσεων της Επιχείρησης ως υπεύθυνης ή εκτελούσας την επεξεργασία και την άσκηση των νόμιμων και συμβατικών της δικαιωμάτων.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Τη διενέργεια ελέγχων προβλεπόμενων από το ισχύον νομοθετικό και κανονιστικό πλαίσιο.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την καταχώριση, καταγραφή και αρχειοθέτηση των πάσης φύσεως εντολών σας προς την Επιχείρηση οι οποίες χορηγούνται είτε εγγράφως, είτε ηλεκτρονικά είτε τηλεφωνικώς, 
                για την κατάρτιση των συναλλαγών και για λόγους προστασίας των συναλλαγών.              
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την αναβάθμιση των παρεχομένων από την Επιχείρηση υπηρεσιών και την προώθηση προϊόντων και υπηρεσιών της Επιχείρησης, και των συνεργαζόμενων με την Επιχείρηση επιχειρήσεων, 
                υπό την προϋπόθεση της προηγούμενης συναίνεσής σας.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την ικανοποίηση των πάσης φύσεως απευθυνόμενων προς την Επιχείρηση αιτημάτων ή την εξέταση παραπόνων σας αναφορικά με προσφερόμενα από την Επιχείρηση προϊόντα και υπηρεσίες.
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την εκπλήρωση νομίμων υποχρεώσεων της Επιχείρησης που πηγάζουν από το ισχύον νομοθετικό και κανονιστικό πλαίσιο.             
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Την προάσπιση των εννόμων συμφερόντων της Επιχείρησης που συνέχονται ενδεικτικά με: α) τη διεκδίκηση των νομίμων αξιώσεών της 
                ενώπιον των αρμοδίων δικαστικών αρχών ή άλλων φορέων εξωδικαστικής / εναλλακτικής επίλυσης διαφορών, β) την πρόληψη της απάτης και 
                άλλων ποινικά διωκόμενων πράξεων, γ) την αξιολόγηση και βελτιστοποίηση διαδικασιών ασφαλείας και πληροφοριακών συστημάτων.
              </li>
            </ul>

            <h5 class="card-title">5. Επεξεργασία δεδομένων προσωπικού χαρακτήρα & κατάρτιση προφίλ για σκοπούς απευθείας εμπορικής προώθησης (marketing)</h5>
            <p class="text-muted">Η Επιχείρηση  ενδέχεται, εφόσον εξασφαλίσει προηγουμένως τη συναίνεσή σας, να επεξεργαστεί τα δεδομένα σας προσωπικού χαρακτήρα προκειμένου 
              να σας ενημερώνει για παρεχόμενα προϊόντα και υπηρεσίες της ή συνεργαζόμενων επιχειρήσεων που πιθανόν να σας ενδιαφέρουν.</p>

            <h5 class="card-title">6. Χρόνος τήρησης δεδομένων</h5>
            <p class="text-muted">Η Επιχείρηση διατηρεί τα δεδομένα σας (δεν νοούνται αυτά που επεξεργάζεται ως εκτελούσα την επεξεργασία για λογαριασμό σας) 
              προσωπικού χαρακτήρα για όσο χρονικό διάστημα προβλέπεται ανά περίπτωση, από το εκάστοτε ισχύον νομοθετικό και κανονιστικό πλαίσιο 
              και σε κάθε περίπτωση για χρονικό διάστημα πέντε (5) ετών από την τελευταία ημερολογιακή ημέρα του έτους λήξης της εκάστοτε συναλλακτικής 
              σας σχέσης με την Επιχείρηση.</p>

            <h5 class="card-title">7.Ποιοι είναι οι αποδέκτες των δεδομένων προσωπικού χαρακτήρα</h5>
            <p class="text-muted">Πρόσβαση στα προσωπικά σας δεδομένα έχουν οι υπάλληλοι και συνεργάτες της Επιχείρησης, εντός του πεδίου των αρμοδιοτήτων τους.</p>

            <p class="text-muted">Η Επιχείρηση δεν διαβιβάζει ή γνωστοποιεί προσωπικά σας δεδομένα της σε τρίτους, εκτός εάν πρόκειται για:</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Επιχειρήσεις (εσωτερικού και εξωτερικού) προς τις οποίες έχει αναθέσει, μερικά ή ολικά την για λογαριασμό της εκτέλεση της επεξεργασίας των δεδομένων 
                σας προσωπικού χαρακτήρα και οι οποίες έχουν αναλάβει έναντι της Επιχείρησης δέσμευση τήρησης εμπιστευτικότητας είτε α) στο πλαίσιο μεταξύ τους 
                συμβατικής σχέσης με την οποία καθορίζεται το αντικείμενο, ο σκοπός, η διάρκεια της επεξεργασίας, το είδος των δεδομένων προσωπικού χαρακτήρα που 
                επεξεργάζονται και τα δικαιώματα της Επιχείρησης, είτε β) στο πλαίσιο κανονιστικής υποχρέωσής τους για τήρηση εμπιστευτικότητας, όπως ενδεικτικά: 
                Εταιρείες/οργανισμούς παροχής υπηρεσιών πληρωμών και επεξεργασίας πληρωμών (π.χ. ΔΙΑΣ, VISA, Μastercard)
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Πιστωτικά Ιδρύματα, Ιδρύματα Πληρωμών.
              </li>
            </ul>
            <p class="text-muted">Η Επιχείριση έχει νομίμως διασφαλίσει ότι οι εκτελούντες για λογαριασμό της την επεξεργασία πληρούν τις προϋποθέσεις και παρέχουν 
              επαρκείς διαβεβαιώσεις για την εφαρμογή των κατάλληλων τεχνικών και οργανωτικών μέτρων, ώστε η επεξεργασία των προσωπικών σας δεδομένων να διασφαλίζει 
              την προστασία των δικαιωμάτων τους.</p>

            <h5 class="card-title">8. Διαβίβαση σε τρίτες χώρες</h5>
            <p class="text-muted">Η Επιχείρηση δεν διαβιβάζει απευθείας προσωπικά σας δεδομένα σε τρίτες χώρες ή διεθνείς οργανισμούς εκτός εάν η 
              διαβίβαση επιβάλλεται από το ισχύον κανονιστικό ή νομοθετικό πλαίσιο.</p>

            <h5 class="card-title">9. Δικαιώματα</h5>
            <p class="text-muted">Ως υποκείμενο επεξεργασίας προσωπικών δεδομένων διατηρείτε τα υποκειμένου ακόλουθα δικαιώματα:</p>
            <ul class="list-unstyled text-muted">
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα πρόσβασης στα δεδομένα προσωπικού χαρακτήρα που σας αφορούν και εφόσον αυτά υφίστανται επεξεργασίας από την Επιχείρηση, 
                ως υπεύθυνη επεξεργασίας, τους σκοπούς αυτής, τις κατηγορίες των δεδομένων και τους αποδέκτες ή τις κατηγορίες αποδεκτών αυτών (άρθρο 15 ΓΚΠΔ).
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα διόρθωσης ανακριβών δεδομένων καθώς και συμπλήρωσης ελλιπών δεδομένων (άρθρο 16 ΓΚΠΔ).
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα διαγραφής των προσωπικών σας δεδομένων υπό την επιφύλαξη των υποχρεώσεων και των νομίμων δικαιωμάτων της Επιχείρησης για τη διακράτησή 
                τους με βάση τις εκάστοτε ισχύουσες νομοθετικές και κανονιστικές διατάξεις (άρθρο 17 ΓΚΠΔ).               
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα περιορισμού της επεξεργασίας των προσωπικών σας δεδομένων εφόσον, είτε αμφισβητείται η ακρίβεια αυτών, είτε η επεξεργασία είναι παράνομη, 
                είτε εξέλειπε ο σκοπός της επεξεργασίας και υπό την προϋπόθεση ότι δεν συντρέχει νόμιμος λόγος για τη διακράτησή τους (άρθρο 18 ΓΚΠΔ).   
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα φορητότητας των προσωπικών σας δεδομένων σε άλλον υπεύθυνο επεξεργασίας, υπό την προϋπόθεση ότι η επεξεργασία βασίζεται σε συγκατάθεση σας
                 και διενεργείται με αυτοματοποιημένα μέσα. Η ικανοποίηση του εν λόγω δικαιώματος τελεί υπό την επιφύλαξη των νομίμων δικαιωμάτων και υποχρεώσεων 
                 της Επιχείρησης για τη διακράτηση των δεδομένων και την εκπλήρωση καθήκοντός της προς το δημόσιο συμφέρον (άρθρο 20 ΓΚΠΔ).
              </li>
              <li>
                <i-feather name="arrow-right" class="fea icon-sm me-2"></i-feather>
                Δικαίωμα εναντίωσης για λόγους που αφορούν στην ιδιαίτερη κατάστασή σας στην περίπτωση που τα δεδομένα σας προσωπικού χαρακτήρα υφίστανται επεξεργασία 
                για την εκπλήρωση καθήκοντος που εκτελείται προς το δημόσιο συμφέρον ή κατά την άσκηση δημόσιας εξουσίας που έχει ανατεθεί στην Επιχείρηση ή για τους 
                σκοπούς των εννόμων συμφερόντων που επιδιώκει η Επιχείρηση ή τρίτο μέρος.                
              </li>
            </ul>

            <h5 class="card-title">10. Τρόπος άσκησης Δικαιωμάτων</h5>
            <p class="text-muted">Κάθε αίτημά σας αναφορικά με τα προσωπικά σας δεδομένα και την άσκηση των δικαιωμάτων σας των δικαιωμάτων σας πρέπει να απευθύνεται 
              εγγράφως προς την ταχυδρομική διεύθυνση που ως άνω αναφέρεται ως έδρα της Επιχείρησης ή είτε στην ηλεκτρονική διεύθυνση 
              <a href="mailto:support@orderify.gr" class="text-primary">support@orderify.gr</a>.</p>

            <h5 class="card-title">11. Προσφυγή</h5>
            <p class="text-muted">Άρνηση της Επιχείρησης ή αναιτιολόγητη καθυστέρηση ως προς την ικανοποίηση των αιτημάτων σας κατά την άσκηση των δικαιωμάτων σας, 
              σας παρέχει το δικαίωμα να προσφύγετε στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα ως καθ’ ύλην αρμόδια εποπτική αρχή για την εφαρμογή ΓΚΠΔ.</p>
            <p class="text-muted">Σε κάθε περίπτωση διατηρείτε το δικαίωμα να υποβάλλετε καταγγελία στην αρμόδια εποπτική αρχή εφόσον θεωρείτε ότι η επεξεργασία των 
              δεδομένων σας προσωπικού χαρακτήρα γίνεται κατά παράβαση της εκάστοτε ισχύουσας νομοθεσίας. Για περισσότερες πληροφορίες μπορείτε να επισκεφθείτε την 
              ιστοσελίδα <a href="https://www.dpa.gr/" target="blank" class="text-primary">www.dpa.gr</a>.</p>

            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Εκτύπωση</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->